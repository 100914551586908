function Header() {
    return (
        <>
            <div id="header-container">
                <img id="logo" alt="Genial logo" src={require("../assets/logo.png")}/>
            </div>
        </>
    )
};

export default Header;